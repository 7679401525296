import { FedopsLogger } from '@wix/fedops-logger'
import { createFedopsLogger } from '@wix/thunderbolt-commons'
import { platformBiLoggerFactory } from '../../bi/biLoggerFactory'
import { PlatformEnvData, SessionServiceAPI } from '@wix/thunderbolt-symbols'

export const createFedopsLoggerFactory = ({ biData, viewMode, sessionService }: { biData: PlatformEnvData['bi']; viewMode: 'site' | 'preview'; sessionService: SessionServiceAPI }): FedopsLogger =>
	createFedopsLogger({
		biLoggerFactory: platformBiLoggerFactory({ sessionService }).createBiLoggerFactoryForFedops(biData),
		customParams: {
			isMobileFriendly: biData.isMobileFriendly,
			viewerName: 'thunderbolt',
			viewMode
		}
	})
